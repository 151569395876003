<template>
    <FormWrapper>
        <PageTitle title="Add/Update Menu" slot="title" link="/helpContent/Menu Detail" />
        <br />
        <ValidationObserver ref="validator" >
            <FormRow>
                <div class="col-sm-2" >
                    <FormSelect label="Select Module" :items="moduleList" item-name="ModuleName" item-value="id"  v-model="form.selectedModule" :onChange="getParents" rules="required" />
                </div>
                <div class="col-sm-3">
                    <InputText v-model="form.name" rules="required" label="Name"  />
                </div>
                <div class="col-sm-3">
                    <InputText v-model="form.filePath" rules="required" label="File Path"  />
                </div>
                <div class="col-sm-4">
                    <InputText v-model="form.breadCrumb" rules="required" label="BreadCrumb"  />
                </div>
            </FormRow>
            <FormRow>
                <div class="col-sm-2" >
                    <FormSelect label="Parent" :items="parentList" item-name="Name" item-value="ID"  v-model="form.selectedParent" />
                </div>
                <div class="col-sm-3">
                    <InputText v-model="form.displaySequence" label="Display Sequence"  />
                </div>
                <div class="col-sm-2">
                    <FormCheckbox v-model="form.isActive">IsActive</FormCheckbox>
                </div>
                <div class="col-sm-5">
                    <div :class="$style.imageFile">
                        <div :class="$style.imageFileLabel">
                            <span>Icon</span>
                        </div>
                        <div :class="$style.imageFileInput">
                            <InputFile :rules="{uploadRequired:false, docCheck:'jpg,jpeg,png', checkFileRegex: true}" :value="form.image" name="Icon" label="Choose File" @onChange="uploadFileHandler"/>
                            <p :style="{color:'grey'}">File dimensions should be 24x24 pixels</p>
                        </div>
                    </div>
                </div>
            </FormRow>
            <br>
        </ValidationObserver>
        <FormRow>
            <div class="col-sm-6" >
                <ButtonGroup>
                    <FormButton type="info" @click="()=>{$router.go(-1)}">Back</FormButton>
                    <FormButton type="success" @click="sendData()">Save</FormButton>
                </ButtonGroup>
            </div>
        </FormRow>
        <Popup title="Success" type="success" :value="showMessage.isVisible"  @close="() => (showMessage.isVisible = false)" :closable="true">
            {{ showMessage.message }}
            <div slot="footer">
                <FormButton @click="()=>{$router.go(-1)}">Ok</FormButton>
            </div>
        </Popup>
    </FormWrapper>
</template> 

<script>
    import FormWrapper from 'Components/form/FormWrapper';
    import PageTitle from 'Components/layout/PageTitle';
    import loadingMixin from 'Mixins/loadingMixin';
    import FormCheckbox from 'Components/form/FormCheckbox';
    import InputFile from "Components/form/InputFile";
    import {getAllModule, getAllParent, addUpdateMenu, getAllMenu} from '../api';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: "Add",
        mixins: [loadingMixin],
        components: {
            FormWrapper,
            FormCheckbox,
            InputFile,
            PageTitle,
            ValidationObserver
        },
        data() {
            return {
                showMessage:{
                    isVisible: false,
                    message: ''
                },
                form:{
                    selectedModule:null,
                    name:'',
                    filePath:null,
                    breadCrumb:null,
                    selectedParent:0,
                    displaySequence:null,
                    isActive:0,
                    image:null
                },
                moduleList:[],
                parentList:[]
            }
        },
        computed: {
          userData() {
              return this.$store.state.user.user
            }
        },
        watch: {
        },
        created() {
            this.getAllList(),
            this.getDataByFilter()
        },
        methods: {
            getDataByFilter() {
                if(this.$route.params.id){
                    setTimeout(() => {
                        this.showLoader();
                        const filters = {};
                        filters.menuId = atob(this.$route.params.id);
                        const data = {
                            filterjson: {
                                filter: [filters]
                            }
                        };
                        getAllMenu(data)
                            .then(this.handleResponse)
                            .catch(this.handleError)
                    },
                    50
                    )
                }
            },
            sendData(){
                this.$refs.validator.validate().then(result=>{
                    if(result){
                        this.showMessage.isVisible=false;
                        
                        const formData = new FormData();
                        formData.append('menuId', this.$route.params.id?atob(this.$route.params.id):0);
                        formData.append('moduleId', this.form.selectedModule);
                        formData.append('menuName', this.form.name);
                        formData.append('filePath', this.form.filePath);
                        formData.append('breadCrumb', this.form.breadCrumb);
                        formData.append('parentId', this.form.selectedParent);
                        formData.append('displaySequence', this.form.displaySequence);
                        formData.append('isActive', this.form.isActive);
                        formData.append('image', this.form.image);

                        addUpdateMenu(formData).then((res)=>{
                            this.form = {};
                            this.form.image = null;
                            this.showMessage.isVisible=true;
                            this.showMessage.message=res.data[0].message;
                        })
                    }
                })
            },
            getParents(ele){
                if (ele == undefined) {
                    this.form.selectedModule=this.userData.user_id;
                } else{
                    this.form.selectedModule=ele;
                }
                let data={
                    moduleId: this.form.selectedModule
                }
                getAllParent(data).then((res)=>{
                    this.parentList=res.data
                });
            },
            async getAllList() {
                await getAllModule().then((res)=>{
                    this.moduleList=res.data;
                    this.form.selectedModule=this.userData.user_id;
                    this.getParents();
                })
            },
            uploadFileHandler(file) {
                this.form.image = file;
            },
            handleResponse(response) {
                this.hideLoader();
                this.form.selectedModule = response.data[0].Module_id;
                this.getParents(this.form.selectedModule);
                this.form.name = response.data[0].Name;
                this.form.filePath = response.data[0].FilePath;
                this.form.breadCrumb = response.data[0].BreadCrumb;
                this.form.selectedParent = response.data[0].Parent_id;
                this.form.displaySequence = response.data[0].displayseq;
                this.form.isActive = response.data[0].IsActive?true:false;
            },
        }
    }
</script>
<style lang="scss" module>
    .imageFile {
        display: flex;
        padding: 5px 0;
    }

    .imageFileLabel {
        width: 120px;
        flex-shrink: 0;
        flex-grow: 0;
        align-items: center;
        display: flex;
    }

    .imageFileInput {
        flex-grow: 1;
    }

    body{
        padding-right: 0px !important;
        overflow: auto !important;
    }
</style>